import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "om-oss"
    }}>{`Om Oss`}</h1>
    <p>{`Jag som driver den här sidan heter Tobias och är jag är baserad strax utanför Göteborg på västkusten.
Vill du komma i kontakt med mig så når du mig på `}<a href="mailto:info@nyaskosnoren.se"><a parentName="p" {...{
          "href": "mailto:info@nyaskosnoren.se"
        }}>{`info@nyaskosnoren.se`}</a></a>{`.`}</p>
    <p>{`Idén till den här hemsidan dök upp när jag själv skulle köpa nya skosnören. Jag insåg snabbt att dessa såldes på spridda ställen över internet och det var svårt
att filterera och hitta de skosnörerna som passade just mina skor. Jag lade då grunden för denna hemsida för att enkelt kunna söka och filtrera bland flera hundra olika skosnören hos mängder med svenska webbutiker.`}</p>
    <h2 {...{
      "id": "affiliateprogram"
    }}>{`Affiliateprogram`}</h2>
    <p>{`De flesta länker från NyaSkosnören till webbutiker är så kallade affiliate-länker. Detta betyder att klickar du på en sådan länk och sedan
genomför ett köp, så kommer jag få provision. Denna ersättning använder jag för att drifta hemsidan (servrar, domän, utveckling m.m.), mycket mer än så räcker det inte till.`}</p>
    <h2 {...{
      "id": "cookies"
    }}>{`Cookies`}</h2>
    <p>{`När du besöker vår hemsida för första gången så får du en fråga om du vill godkänna Cookies. Väljer du att godkänna så använder vi oss utav en cookie för att analysera besöken på vår hemsida
via Google Analytics. Vi kan se statistik så som vilka sidor som är mest populära, hur länge användare stannar, vilket skosnören som är mest populära och så vidare. Denna
statistk använder vi sedan för att lägga populära skosnören högt upp, förbättra välbesökta sidor m.m.`}</p>
    <p>{`Vill du vet mer om hur Google hanterar datan så kan du läsa mer på `}<a target="_blank" href="https://policies.google.com/technologies/partner-sites?hl=sv">{`Google Integritet och Användarvillkor`}</a>{`.
Du kan även hitta användbar information på `}<a target="_blank" href="https://business.safety.google/compliance/">{`Google - GDPR Compliance`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      